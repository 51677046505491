<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" header-class="center-modal-title" size="lg">
        <template slot="modal-title">
            <span class="modal-title">{{ jeu.nom }}</span>
        </template>
        <b-col lg="10" offset-lg="1" class="text-center">
            <b-img :src="displayPicture" alt="Image du jeu" fluid class="picture-object-image"/>

            <table class="mt-5">
                <tbody>
                <tr>
                    <td class="text-right"><strong>Nombre de joueurs :</strong></td>
                    <td class="text-left">{{ jeu.joueursMin || '-' }} à {{ jeu.joueursMax || '-' }}</td>
                </tr>
                <tr>
                    <td class="text-right"><strong>Caution :</strong></td>
                    <td class="text-left">{{ jeu.caution || '-' }} €</td>
                </tr>
                <tr>
                    <td class="text-right"><strong>Propriétaire :</strong></td>
                    <td class="text-left">
                        <user-name :user="jeu.proprietaire" default-cluji/>
                    </td>
                </tr>
                <tr v-if="jeu.tags.length">
                    <td class="text-right"><strong>Tags :</strong></td>
                    <td class="text-left">
                        <borrowable-tag-badge v-for="tag in jeu.tags" :key="'tag_'+tag.id" :tag="tag"/>
                    </td>
                </tr>
                </tbody>
            </table>

            <div v-if="jeu.description" class="text-left">
                <hr/>

                <b-row>
                    <b-col cols="12">
                        <div class="ck-content" v-html="sanitize(jeu.description)"></div>
                    </b-col>
                </b-row>
            </div>

            <hr/>

            <span v-b-tooltip.hover="borrowForbidden ? `Seuls les membres d'une association (Cluji ou autre) peuvent emprunter des jeux de rôle` : null">
                <b-button :disabled="borrowForbidden" @click="borrow" variant="info" v-if="$route.name !== 'creation_emprunt'" class="mr-2">
                    Emprunter
                </b-button>
            </span>
            <b-button @click="hideModal" variant="secondary">Fermer</b-button>
        </b-col>
    </b-modal>
</template>

<script>
    import modalMixin   from '@/mixin/modalMixin';
    import {pictureSrc} from '@/util/image';
    import {sanitize}   from 'dompurify';
    import {isAnyAsso}  from '@/util/auth';

    const BorrowableTagBadge = () => import('@/components/BorrowableTagBadge');
    const UserName           = () => import('@/components/UserName');

    export default {
        name: "DisplayJeuModal",
        components: {BorrowableTagBadge, UserName},
        mixins: [modalMixin],
        props: {
            jeu: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            modalRef: 'displayJeuModal'
        }),
        computed: {
            displayPicture() {
                return pictureSrc(this.jeu, 'jeux');
            },
            borrowForbidden() {
                return !isAnyAsso();
            }
        },
        methods: {
            sanitize,
            borrow() {
                this.$router.push({name:'creation_emprunt', query: { jeu: this.jeu.id }});
                this.hideModal();
            }
        }
    }
</script>

<style scoped>
    header.modal-header .modal-title {
        font-size: 1.5rem;
    }

    img {
        max-width: 256px;
        width: 50%;
    }

    table {
        width: 100%;
    }

    td.text-right {
        width: 50%;
    }

    td.text-left {
        padding-left: 10px;
    }
</style>